@import "./variables.scss";

.support {
    display: grid;
}
.support-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: max-content;
}
.support-heading {
    display: flex;
    justify-content: space-between;
    padding: $mobile-padding;
    column-gap: 1rem;
    button {
        background: $primary-color;
        color: white;
        padding: 1rem 2rem;
        border: none;
    }
}
.two-button-head {
    display: grid;
    row-gap: 1rem;
    a {
        display: flex;
        justify-content: center;
        justify-items: flex-end;
        font-size: .75rem;
        text-align: center;
        background: $primary-color;
        padding: .5rem 1rem;
        color: white;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
            background: $secondary-color;
        
        }
    }
}

.support-section-header {
    display: flex;
    margin: 1rem 0rem;
    text-transform: capitalize;

}
.support-section-body {
    display: grid;
    justify-items: center;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    padding: 1rem 0rem;
    border-bottom: 2px solid #333;
    .support-image-wrapper {
        width: 200px;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}
.support-downloads {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: max-content;
    row-gap: 1rem;
    a {
        text-decoration: underline;
        color: black;
    }
}
.download {
    display: grid;
    width: 75px;
    justify-content: center;
    justify-items: center;
    font-size: .8rem;
    text-align: center;
    row-gap: .5rem;
    height: max-content;
    cursor: pointer;
    img {
        width: 35px;
        height: auto;
    }
}

@include responsive(small) {

    .support-section-body {
        display: flex;
        column-gap: 2rem;
        align-items: center;
        .support-image-wrapper {
            width: 250px;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

    }
    .support-downloads {
        justify-content: space-around;
        align-items: flex-start;
        img {
            width: 75px;
        }
        a {
            color: black;
        }

    }
}

@include responsive(medium) {
    .support-heading {
        padding: $notepad-padding;
    }
}
@include responsive(large) {
    .support-heading {
        padding: $desktop-padding;
    }
}