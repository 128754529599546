@import "./variables.scss";

.request-demo-section {
    display: grid;
    width: 100%;
    justify-content: center;
    background-repeat: no-repeat;
    background-color: #444;
    background-size: cover;
    color: white;
    padding: $mobile-box-padding;
    h1, h2 {
        padding: .5rem 0rem;
    }
}
.request-demo {
    display: grid;
    grid-template-columns: 1fr;
    background: rgb(24, 24, 24);
    padding: 2rem 2rem;
}

@include responsive(medium) {
    .request-demo-section {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        column-gap: 2rem;
        padding: $notepad-box-padding;
    }
}

@include responsive(large) {
    .request-demo-section {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        column-gap: 2rem;
        padding: $desktop-box-padding;
    }
    .request-demo {
        display: grid;
        justify-self: center;
        width: 600px;
        .two-col {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;
        }
        .one-col{
            display: grid;
            grid-template-columns: 1fr;
        }
    }
}