@import "./variables.scss";

.parts-page {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    min-height: calc(90vh - 3.5rem);
    justify-content: center;
    width: 100%;
    overflow: hidden;
    padding-top: 3.5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    .parts-page_header {
    padding: 1rem 0rem;
    }
    .parts-page_body {
        display: grid;
        align-items: flex-start;
        a {
            display: flex;
            text-decoration: none;
            color: black;
            font-weight: bold;
            background: $primary-color;
            padding: 1rem 2rem;
            width: max-content;
            height: max-content;
            margin-bottom: 1rem;
            transition: all .3s ease 0s;
            &:hover {
                background: black;
                color: white;
            
            }
        }
        p {
            padding: 0rem;
            padding-bottom: 1rem;
        }
        .parts_page_part_image {
            display: grid;
            width: 100%;
            object-fit: cover;
            object-position: bottom;
            margin-bottom: 1rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: bottom;
            }
        }
    }
}

@include responsive(medium) {
    .parts-page {
        margin: auto;
        max-width: 750px;
        .parts-page_body {
            display: grid;
            column-gap: 1rem;
        }
    }
}