@import "./variables.scss";


.section-market {
    display: grid;
    grid-template-columns: 1fr;
}

.left-column {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    background: $primary-color;
    color: white;
    width: 100%;
    overflow: hidden;
    align-content: flex-start;
    .section-title  {
        display: grid;
        justify-content: center;
        text-align: center;
        padding: 1rem;
        height: auto;
    }
    .markets-list {
        display: grid;
        justify-items: center;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        align-content: flex-start;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        padding: .5rem;
        ul {
            display: grid;
            justify-content: center;
            align-content: flex-start;
            width: 100%;
            padding: 1rem 0rem;
            li {
                padding: .25rem 0rem;
            }
        }
    }
    .markets-wrapper {
        display: none;
    }
}
.right-column {
    display: grid;
    width: 100%;
    height: 300px;
    overflow: hidden;
    iframe {
        width: 100%;
        height: 100%;
    }
}

@include responsive(small) {
    .right-column {
        display: grid;
        width: 100%;
        height: 400px;
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@include responsive(medium) {
    .section-market {
        display: grid;
        grid-template-columns: 1fr 1fr;
    } 
    .left-column {
        display: grid;
        height: 100%;
        grid-template-rows: 1fr auto;
        align-items: flex-start;
        align-content: flex-start;
        .markets-list {
            grid-template-columns: 1fr 1fr 1fr;
        }
        .markets-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .box-wrapper {
                display: grid;
                cursor: pointer;
                height: 200px;
                width: 100%;
                position: relative;
                overflow: hidden;
                opacity: .9;
                &:hover {
                    opacity: 1;
                }
                img {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .market-wrapper-overlay {
                    display: grid;
                    height: max-content;
                    width: 100%;
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    h1 {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        padding: 1rem 0rem;
                        margin: 0;
                        height: max-content;
                        width: 100%;
                        text-align: center;
                        background: rgba(0, 0, 0, 0.5);
                    }
                }
                .markets-image-wrapper {
                    display: flex;
                    height: inherit;
                    width: 100%;
                    overflow: hidden;
                    img {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .right-column {
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
        }
    }

}

@include responsive(large) {

}
