@import "./variables.scss";

.desktop-navigation {
    display: none;
}
.desktop-navigation-menu {
    display: none;
}
.desktop-dropdown-wrapper {
    display: none;
}
.mobile-navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 0;
    align-items: center;
    height: 3.5rem;
    background: $primary-color;
    padding: $mobile-padding;
    z-index: 10;
    .logo-wrapper {
        img {
            height: auto;
            width: 150px;
            object-fit: contain;
        }
    }
    svg {
        color: white;
        font-size: 1.65rem;
        cursor: pointer;
        &:hover {
            color: $secondary-color;
        }
    }
}
.mobile-menu-wrapper {
    display: grid;
    position: fixed;
    height: 100vh;
    width: 100vw;
    transform: translateX(-100%);
    transition: ease-in-out 0.3s;
    z-index: 999;
    background: rgba($color: #000000, $alpha: .5);
    &.open {
        display: grid;
        transform: translateX(0%);
    }
}
.mobile-menu {
    display: grid;
    width: 100%;
    height: max-content;
    align-items: flex-start;
    align-content: flex-start;
    background: rgb(39, 39, 39);
    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding: $mobile-box-padding;

        li {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            cursor: pointer;
            padding: .75rem .5rem;
            border-radius: .125rem;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .225);
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                svg, a {
                    color: $primary-color;
                }
            }

            a {
                text-decoration: none;
                color: white;
                font-weight: 600;
                width: 100%;
                &.request-a-demo {
                    text-align: center;
                    background: $primary-color;
                    padding: .5rem 1rem;
                    color: white;
                    &:hover {
                        background: $secondary-color;
                    }
                }
                &.login {
                    font-weight: normal;
                    text-align: center;
                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
            svg {
                color: white;
            }
        }
    }
    .mobile-menu-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: .5rem;
        background: $primary-color;
        svg {
            font-size: 1.5rem;
            color: white;
            cursor: pointer;
        }
        a {
            img {
                width: 130px;
                height: auto;
            }

        }
    }
}

@include responsive(medium) {
    .mobile-navigation {
        display: none;
    }
    .mobile-menu-wrapper {
        display: none;
        &.open {
            display: none;
        }
    }
    .mobile-menu {
        display: none;
    }
    .desktop-navigation {
        display: flex;
        justify-content: space-between;
        position: fixed;
        z-index: 12;
        width: 100%;
        align-items: center;
        height: 3.5rem;
        background: $primary-color;
        padding: $notepad-padding;
        box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: .225);
        img {
            width: 150px;
            height: auto;
        }
        .navigation-links {
            display: flex;
            justify-content: left;
            margin-left: 4rem;
            a, button {
                font-size: .95rem;
                background: transparent;
                border: none;
                color: $white-color;
                text-decoration: none;
                margin: 0rem 1rem;
                cursor: pointer;
                &:hover {
                    color: $dark-grey;
                }
            }
        }
        .navigation-cta {
            justify-self: end;
            a {
                color: $light-grey;
                text-decoration: none;
                padding: 0.35rem 1rem;
                border: 1px solid $light-grey;
                border-radius: 5px;
                margin: 0rem 0rem 0rem 1rem;
                &:hover {
                    background: $light-grey;
                    color: $primary-color;
                }
            }
        }
        .navigation-logo {
            justify-self: flex-start;
            img {
                height: 2.5rem;
                width: 100%;
            }
        }
    }
    .desktop-navigation-menu {
        display: flex;
        transform-origin: center bottom;
        transform: translateY(-100%);
        transition: ease-in-out 0.3s;
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 674px;
        background: red;
        &.open {
            transform: translateY(0%);
 
        }
        .desktop-dropdown-wrapper {
            display: grid;
            position: fixed;
            top: 3.5rem;
            width: 100%;
            z-index: 3;
            grid-template-columns: 1fr 1fr 4fr;
            box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: .225);
            ul {
                display: grid;
                li {
                    display: grid;
                    &.selected {
                        background: $secondary-color;
                        color: $white-color;
                        button {
                            color: $white-color;
                        }
                    }
                    button {
                        display: grid;
                        align-items: center;
                        align-content: center;
                        text-decoration: none;
                        text-align-last: left;
                        padding: 0rem 1rem;
                        width: 100%;
                        height: 4rem;
                        text-align: left;
                        font-size: 1rem;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                        text-transform: capitalize;
                        font-weight: bold;
                        span {
                            text-transform: none;
                            font-size: .8rem;
                            font-weight: normal;
                            font-style: italic;
                        }
                    }
                }
    
            }
            h1 {
                font-weight: normal;
                width: min-content;
                font-size: 1.5rem;
                padding: 1rem 1rem 0rem 1rem;
            }

            .col-1 {
                background: $white-color;
                h1 {
                    font-weight: bold;
                }
                h3 {
                    font-weight: normal;
                    font-size: 1rem;
                    font-style: italic;
                    padding: .5rem 1rem;
                }

            }
            .col-2 {
                background: $secondary-color;
                color: white;
                h1 {
                    width: inherit;
                    font-size: 1.5rem;
                    padding: 1rem 1rem 0rem 1rem;
                }
                h3 {
                    font-weight: normal;
                    font-size: 1rem;
                    font-style: italic;
                    padding: .5rem 1rem;
                }
                .deck-name {
                    font-weight: bold;
                    font-size: 1rem;
                }
                ul {
                    button {
                        font-weight: lighter;
                        color: white;
                        &:hover {
                            background: $hover-color;
                        }
                    }
                }
            }
            .col-3 {
                background: $white-color;
                background-size: cover;
                background-position: center;
            }
        }
    }
    .selected-machine-section {
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-content: center;
        background: rgb(255, 255, 255);
        row-gap: 1rem;
        cursor: pointer;
        width: 100%;
        height: 100%;
        h2 {
            color: rgb(15, 15, 15);
            text-transform: capitalize;
            text-align: center;
            width: 100%;
        }
        .selected-machine-variants {
            display: flex;
            justify-content: space-between;
        }
        .selected-machine-series {
            display: grid;
            color: white;
        }
        .selected-machine-image {
            cursor: pointer;
            img {
                width: 350px;
                height: 350px;
            }
        }
        .deck-type-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            justify-items: center;
            width: inherit;
            margin-top: 1rem;
            img {
                width: 65%;
                height: 100%;
                object-fit: contain;
            }
            .deck-type-bullet {
                display: grid;
                justify-content: flex-start;
                text-align: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 1.25rem;
                font-weight: bold;
                border-radius: 50%;
                padding: 1rem;
                h5 {
                    color: black;
                }
                ul {
                    display: grid;
                    row-gap: .25rem;
                    margin-top: .25rem;
                    li {
                        display: flex;
                        font-size: 1rem;
                        font-weight: 100;
                        font-size: .85rem;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                
                }
            }
        }
        .selected-machine-content {
            display: grid;
        }
        .selected-machine-deck-type {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .left-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        .right-section {
            display: flex;
            row-gap: 1rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // background: rgba($color: #000000, $alpha: .25);
            height: 100%;
            background: rgb(226, 226, 226);
        }
        .selected-machine-specs {
            padding: 1rem;
            h4 {
                padding: 0rem;
                padding-bottom: .5rem;
            }
            ul {
                display: grid;
                color: black;
                font-size: .85rem;
                row-gap: .5rem;
                li {
                    display: flex;
                }
                span {
                    &.label {
                        font-weight: bold;
                        margin-right: .5rem;
                    }
                    &.description {
                        font-weight: lighter;
                        font-style: italic;
                    }
                }
            }
        }
     }
}
@include responsive(large) {
    .desktop-navigation {
        padding: $desktop-padding;
    }
    .desktop-navigation-menu {
        .desktop-dropdown-wrapper {
            display: grid;
            position: fixed;
            top: 3.5rem;
            width: 100%;
            grid-template-columns: 1fr 1fr 5fr;
            box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: .225);
            ul {
                display: grid;
                li {
                    &.selected {
                        background: $secondary-color;
                        color: $white-color;
                        button {
                            color: $white-color;
                        }
                    }
                }
                button {
                    text-decoration: none;
                    text-align-last: left;
                    padding: 1.5rem 1rem;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    color:#000000;
                    font-weight: 600;
                    font-size: 1.25rem;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    span {
                        font-weight: normal;
                        font-size: 1rem;
                        font-style: italic;
                    }
                }
            }
            h1 {
                width: min-content;
                font-size: 1.75rem;
                padding: 1rem 1rem 0rem 1rem;
            }
            h3 {
                font-weight: normal;
                font-style: italic;
                padding: .5rem 1rem;
            }
            .col-1 {
                background: $white-color;
    
            }
            .col-2 {
                background: $secondary-color;
                color: white;
                h1 {
                    width: inherit;
                    font-size: 1.75rem;
                    padding: 1rem 1rem 0rem 1rem;
                }
                h3 {
                    font-weight: normal;
                    font-style: italic;
                    padding: .5rem 1rem;
                }
                ul {
                    button {
                        color: white;
                        &:hover {
                            background: $hover-color;
                        }
                    }
                }
            }
            .col-3 {
                background-size: cover;
            }
        }
    }

}