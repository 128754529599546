@import './variables.scss';

.alert-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba($color: #000000, $alpha: .5);
    padding: 1rem;
    p {
        color: black;
    }
    .alert-content-wrapper {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        height: max-content;
        color: white;
        box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
        .alert-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: black;
            border-bottom: 1px solid $primary-color;
            padding: 1rem;
            width: 100%;
            .alert-heading {
                width: min-content;
            }
            .close-icon {
                display: grid;
                cursor: pointer;
                &:hover {
                    color: $primary-color;
                }
            }

        }
        .alert-message {
            padding: 1rem;
            background: white;
            color: black;
            a {
                color: $secondary-color;
            }
        }
    }

}

@include responsive(medium) {
    .alert-content-wrapper {
        max-width: 500px;
     }
}

