@import "./variables.scss";

.footer {
    display: grid;
    grid-template-columns: 1fr;
    background: $tertiary-color-lighter;
    .section-head {
        font-weight: bold;
        color: $white-color;
        margin-bottom: .125rem;
    }
    ul {
        display: grid;
        align-items: flex-start;
        align-content: flex-start;
        li {
            padding: .25rem 0rem;
            a {
                font-size: .9rem;
                color: $white-color;
                &:hover {
                    color: $secondary-color;
                
                }
            }
        }
    }
    .footer-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        padding: $mobile-box-padding;
    }
    .footer-image-wrapper {
        display: flex;
        width: 100%;
        img {
            justify-content: center;
            justify-self: center;
            width: 200px;
            height: auto;
        }
    }
}
.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $tertiary-color-light;
    padding: $mobile-box-padding;
    color: $color-grey;
    width: 100%;
    font-size: .75rem;


}

@include responsive(medium) {
    .footer {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        .footer-columns {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
            width: 100%;
            padding: $notepad-box-padding;
            ul {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                width: 100%;
            }
        }
        .footer-image-wrapper {
            img {
                width: 300px;
                height: auto;
            }
        }
    }
}
@include responsive(large) {

    .footer {
        .footer-columns {
            display: flex;
            width: 100%;
            padding: $desktop-box-padding;
            ul {
                display: grid;
                width: 100%;
            }
        }

    }
}