@import "./variables.scss";

.all-blog-posts-page {
    display: grid;
    padding: 1rem;
    row-gap: .5rem;
    .all-blog-posts-post {
        display: grid;
        grid-template-columns: 1fr 5fr;
        column-gap: .5rem;
        border-bottom: 1px solid #e0e0e0;
        padding: .5rem 0rem;
        transition: all .1s;
        h1 {
            transition: all .1s;
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.02);
            h1 {
                color: $primary-color;
            }
        }
    }
    .all-blog-posts-post-image {
        width: 75px;
        height: 75px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .all-blog-posts-post-details {
        display: grid;
        align-items: flex-start;
        align-content: flex-start;

    }
    .all-blog-posts-post-details {
        height: 75px;
        overflow: hidden;
        text-overflow: ellipsis;

    }
    .all-blog-posts-heading-details {
        h1 {
            font-size: 1rem;
        }
        h5 {
            font-size: .75rem;
            font-weight: normal;
            font-style: italic;
        }
    }
    .all-blog-posts-content {
        p {
            font-size: .8rem;
            line-height: normal;
        }
    }
    .load-more-button {
        width: 100%;
        padding: 1rem;
        background: #333;
        color: white;
        border: none;
        cursor: pointer;
        transition: all .1s;
        &:hover {
            background: $primary-color;
        }
    }
}

@include responsive(large) {
    .all-blog-posts-page {
        display: grid;
        padding: 1rem;
        row-gap: .5rem;
        width: 800px;
        margin: auto;
        .all-blog-posts-post {
            display: grid;
            grid-template-columns: 1fr 5fr;
            column-gap: .5rem;
            border-bottom: 1px solid #e0e0e0;
            padding: .5rem 0rem;
        }
        .all-blog-posts-post-image {
            width: 120px;
            height: 120px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .all-blog-posts-post-details {
            display: grid;
            align-items: flex-start;
            align-content: flex-start;
    
        }
        .all-blog-posts-post-details {
            height: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
    
        }
        .all-blog-posts-heading-details {
            h1 {
                font-size: 1rem;
            }
            h5 {
                font-size: .75rem;
                font-weight: normal;
                font-style: italic;
            }
        }
        .all-blog-posts-content {
            p {
                font-size: .8rem;
                line-height: normal;
            }
        }
    
    }
}
