.image-carousel-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100vw;
    .image-carousel {
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
    .carousel-image {
        flex: 0 0 auto;
        margin-right: 10px;
        img {
            width: 100%;
            padding: 10px;
        }
      }
      .scroll-button {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        cursor: pointer;
        position: absolute;
        padding: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .scroll-button.left {
        left: 0;
      }

      .scroll-button.right {
        right: 0;
      }
}

@include responsive(small) {
    .image-carousel-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
}
