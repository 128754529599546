@import "./variables.scss";

.cards-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}
.all-machines-series-cards-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2rem;
    width: 100%;
    margin-bottom: 1rem;
}
.all-machines-series-wrapper {
    display: grid;
    justify-content: center;
    margin: auto;
    width: 100%;
    h2 {
        text-transform: uppercase;
    }
}
.series-wrapper {
    display: grid;
    justify-content: center;
    width: 100%;
}
.machine-card {
    display: grid;
    grid-template-rows: auto 1fr;
    border: 1px solid rgba($color: $primary-color, $alpha: 1);
    max-width: 250px;
    height: auto;
    align-items: flex-start;
    align-content: flex-start;
    .machine-image-container {
        display: grid;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        img {
            display: flex;
            width: 100%;
            object-fit: contain;
            height: auto;
        }
    }
    .card-content {
        display: grid;
        grid-template-rows: auto auto 1fr;
        background: $primary-color;
        text-align: center;
        align-items: flex-start;
        align-content: flex-start;
        height: 100%;
        color: white;
        padding: 1rem;
        align-items: flex-start;
        h2 {
            font-weight: normal;
            text-align: center;
            width: 100%;
        }
        a, button {
            display: flex;
            margin-top: 1rem;
            justify-content: center;
            align-self: flex-end;
            text-decoration: none;
            color: white;
            border: 1px solid $white-color;
            transition: all .125s ease-in-out;
            background:transparent;
            padding: 1rem 2rem;
            width: 100%;
            cursor: pointer;
            &:hover {
                background: $tertiary-color;
                color: $white-color;
            }
        }
        ul {
            display: grid;
            width: 100%;
            li {
                font-size: .8rem;
                border-bottom: 1px solid #ffffff;
                padding: .5rem 0;
                text-align: left;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .deck-details-p {
            width: 100%;
            text-align: center;
            font-size: .85rem;
        }
    }
}
.cta-cards {
    display: grid;
    row-gap: 2rem;
    justify-content: center;
    background: white;
    .cta-card {
        display: grid;
        grid-template-rows: auto 1fr;
        width: 275px;
        border: 1px solid rgba($color: #333, $alpha: .25);
        h3 {
            font-weight: normal;
        }
        .cta-card-image {
            display: grid;
            justify-content: center;
            width: 100%;
            height: 180px;
            background: white;
            padding: 1rem 0rem;
            img {
                width: 100%;
                object-fit: contain;
                height: auto;
            }
        }
        .card-content {
            display: grid;
            grid-template-rows: auto auto 1fr;
            background: $primary-color;
            color: white;
            text-align: center;
            padding: 1rem;
            a, button {
                align-self: flex-end;
                background: transparent;
                border: 2px solid white;
                color: white;
                padding: 1rem 2rem;
                height: max-content;
                margin-top: 2rem;
                cursor: pointer;
            &:hover {
                background: $tertiary-color;
            }
            }
        }
    }
}


@include responsive(small) {
    .series-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    .cards-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .all-machines-series-cards-wrapper {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        column-gap: 2rem;
        margin-bottom: 2rem;
        .machine-card {
            max-width: 300px;
            cursor: pointer;
        }
    }
    .all-machines-series-wrapper {
        display: grid;
        flex-direction: column;
        column-gap: 2rem;
        width: 100%;
        justify-content: left;
        h2 {
            text-transform: uppercase;
        }
    }

    .machine-card {
        width: 300px;
        cursor: pointer;
    }
    .cta-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        a {
            text-decoration: none;
            border: none;
            color: transparent;

        }
        .cta-card {
            display: grid;
            .cta-card-image {
                display: grid;
                justify-items: center;
                background: white;
                img {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }

        }
    }
}

@include responsive(medium) {
    .cards-wrapper {
        display: flex;
        flex-direction: row;
        row-gap: 2rem;
    }
    .series-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .all-machines-series-cards-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        column-gap: 2rem;
        margin-bottom: 2rem;
    }
}

@include responsive(large) {
    .cards-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: row;
        row-gap: 2rem;
    }
    .series-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .all-machines-series-cards-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
        column-gap: 2rem;
        margin-bottom: 2rem;
    }
}