@import "./variables.scss";


.centered {
    justify-items: center;
}

.two-columns {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}
.three-columns {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}
.four-columns {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}

@include responsive(small) {

}

@include responsive(medium) {
    .two-columns {
        grid-template-columns: 1fr 1fr;
    }

    .four-columns {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}