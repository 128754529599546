@import "./variables.scss";

.models-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    background-color: $models-background-color;
    padding: $mobile-box-padding;
    row-gap: 1rem;
    h2 {
        font-size: 1rem;
    }
    .model-card {
        display: grid;
        justify-items: center;
        width: 140px;
        align-content: center;
        align-items: center;
        height: auto;
        text-align: center;
        cursor: pointer;
        img {
            width: 100%;
            height: auto;
            margin-bottom: .5rem;
            transition: ease-in-out 0.2s;
            &:hover {
                transform: scale(1.1);
            }
        }
        h2 {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: .5rem;
            width: 100%;
        }
        p {
            text-align: center;
        }
    }
}

.hero-section {
    width: 100%;
    position: relative;
    .hero-image-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 500px;
        overflow: hidden;
        justify-content: center;
        background: black;
        .image-content-wrapper {
            display: flex;
            position: absolute;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            justify-items: center;
            text-align: center;
            color: white;
            z-index: 2;
            width: 100%;
            a {
                display: flex;
                margin-top: 1.5rem;
                text-align: center;
                font-weight: bold;
                font-size: 1.25rem;
                align-self: center;
                padding: 1rem 1.5rem;
                border: 2px solid white;
                width: max-content;
                color: white;                background: #FFA500;
                &:hover {
                    background: $tertiary-color;
                }
            }
        }
        h1 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        h3 {
            font-size: 1.25rem;
            font-weight: normal;
        }
        img {
            display: grid;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center;
            opacity: .75;
            
        }
    }
}

@include responsive(small) {
    .models-container {
        grid-template-columns: 1fr 1fr 1fr;
        .model-card {
            width: 100%;
            width: 170px;
        }
    }
}

@include responsive(medium) {
    .models-container {
        display: flex;
        flex-wrap: wrap;
        padding: $notepad-box-padding;
        justify-content: space-around;
        justify-items: center;

    }

}

@include responsive(large) {
    .hero-section {
        .hero-image-wrapper {
            height: 800px;
            h1 {
                font-size: 3rem;
            }
            h2 {
                font-size: 2.5rem;
            }
            h3 {
                font-size: 1.5rem;
            }
        }
    }
    .models-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .model-card {
            display: grid;
            width: 265px;
            padding: 1rem;
        }
    }
}