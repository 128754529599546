@import './variables.scss';

.machine-applications-wrapper {
    display: flex;
    flex-direction: column;
    padding: $mobile-box-padding;
    .applications-two-columns {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2rem;
        .application-two-columns {
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;
            .application-image-wrapper {
                display: grid;
                position: relative;
                align-items: flex-start;
                overflow: hidden;
                .content-wrapper {
                    position: absolute;
                    z-index: 5;
                    width: 100%;
                    padding: 2rem;
                    text-align: center;
                    color: white;
                    background: rgba($color: $primary-color, $alpha: .75)
                }
                img {
                    width: 100%;
                    height:max-content;
                    object-fit: contain;
                    overflow: hidden;
                }
            }
        }
    }
}

@include responsive(small) {
    
}

@include responsive(medium) {
    .machine-applications-wrapper {
        display: flex;
        flex-direction: column;
        padding: $notepad-box-padding;
    .applications-two-columns {
            display: grid;
            grid-template-columns: 1fr  1fr;
            flex-direction: column;
            column-gap: 2rem;
            row-gap: 6rem;
            .application-two-columns {
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 1rem;
                .application-image-wrapper {
                    display: grid;
                    position: relative;
                }
            }
        }
    }  
}

@include responsive(large) {
    .machine-applications-wrapper {
        display: flex;
        flex-direction: column;
        padding: $desktop-box-padding;
    }
}


