@import "./variables.scss";

.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    row-gap: 1rem;
    .gallery-image-wrapper {
        display: flex;
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@include responsive(small) {
    .images-wrapper {
        flex-direction: row;
        .gallery-image-wrapper {
            width: 50%;
            height: 200px;
        }
    }

}
@include responsive(medium) {
    .images-wrapper {
        .gallery-image-wrapper {
            width: 35%;
        }
    }
}

@include responsive(large) {
    .images-wrapper {
        row-gap: 4rem;
        .gallery-image-wrapper {
            width: 25%;
        }
    }
}


