@import "./variables.scss";

.page {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    height: auto;
    width: 100%;
}
.page-heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-align: center;
    padding: $mobile-padding;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: rgba($color: #000000, $alpha: .125);
    &.w-button {
        display: flex;
        justify-content: space-between;
        padding: $mobile-padding;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: rgba($color: #000000, $alpha: .125);
        .button {
            display: flex;
            padding: 1rem 2rem;
            color: white;
            font-weight: bold;
            background: $primary-color;
            border: 1px solid $primary-color;
            transition: all .3s ease;
            &:hover {
                background: transparent;
                color: $primary-color;
            }   
        }
    }
    h1 {
        font-weight: normal;
        text-transform: capitalize;
    }
}
.page-header {
    display: flex;
    height: 700px;
    width: 100%;
    position: relative;
    .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        position: absolute;
        z-index: 1;
        color: white;
        overflow: hidden;
        h1 {
            text-align: center;
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1rem;
            max-width: 800px;
            font-size: .85rem;
        }
    }
    img {
        object-fit: cover;
        width: 100%;
        height: auto;
        filter: brightness(50%);
    }
}

.videos-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    iframe {
        width: 100%;
        height: 400px;
        object-fit: contain;
    }
}
#google-map {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    width: 100%;
    margin-top: 1rem;
    height: 400px;
    overflow: hidden;
    iframe {
        width: 85%;
        height: 100%;
    }
}


.page-two-columns {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 1rem;
    div {
        display: flex;
        flex-direction: column;
        width: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: auto;
        }
    }
}
p {
    margin-bottom: .5em;
}



@include responsive(small) {
    .videos-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
        column-gap: 2rem;
        iframe {
            width: 100%;
            height: 450px;
            object-fit: contain;
        }
    }
}

@include responsive(medium) {
    .videos-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
        column-gap: 2rem;
        iframe {
            width: 100%;
            height: 450px;
            object-fit: contain;
        }
    }
    .page-header {
        display: flex;
        height: 600px;
        width: 100%;
        position: relative;
        .header-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 1rem;
            position: absolute;
            z-index: 1;
            color: white;
            overflow: hidden;
            h1 {
                text-align: center;
                margin-bottom: 1rem;
            }
            p {
                margin-bottom: 1rem;
                font-size: 1rem;
            }
        }
    }
    .page-heading {
        margin-bottom: 1rem;
        text-align: center;
        padding: $notepad-padding;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        &.w-button {
            display: flex;
            justify-content: space-between;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        h1 {
            font-weight: normal;
        }
    }
    .page-two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}


@include responsive(large) {
    .page-heading {
        justify-content: center;
        margin-bottom: 1rem;
        text-align: center;
        padding: $desktop-padding;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        &.w-button {
            display: flex;
            justify-content: space-between;
            padding: $desktop-padding;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        h1 {
            font-weight: normal;
        }
    }
    .page-two-columns {
        grid-template-columns: 1fr 1fr;
    }

}