@import "./variables.scss";

.options-wrapper {
    display: grid;
    padding: $mobile-box-padding;
    padding: 2rem 0rem;
    background: $tertiary-color;
    color: white;
    .two-columns-wrapper {
        display: grid;
        .option {
            display: grid;
            h3 {
                margin-bottom: .5rem;
            }
            p {
                color: #f0f0f0;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@include responsive(small) {
    .options-wrapper {
        display: grid;
        grid-template-columns: 1fr;
    .two-columns-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 1rem;
            column-gap: 1rem;
            .option {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .option-details {
                    display: grid;
                    align-items: flex-start;
                    align-content: flex-start;
                    padding: 0rem 1rem;
                }
                img {
                    width: 100%;
                    height: auto;
                    border: 1px solid rgba($color: #000000, $alpha: .25);
                }
            }
         }
    }
}

@include responsive(medium) {
    .options-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        padding: $notepad-box-padding;
        .two-columns-wrapper {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@include responsive(large) {
    .options-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        padding: $desktop-box-padding;
    }
}