$primary-color: #4d96f5;
$secondary-color: #5595f7; //Menu Background Color
$hover-color: #4088e5; //Menu Background Color
$light-grey: #cacaca;
$color-grey: #cacaca;
$dark-grey: #4a4a4a;
$models-background-color: #e6e6e6;
$tertiary-color: rgb(247, 147, 30);
$tertiary-color-hover: rgb(249, 165, 68);
$olive-green: rgb(2, 3, 2);;
$tertiary-color-light: #1f1f1f;
$tertiary-color-lighter: rgb(56, 56, 56);
$white-color: #ffffff;
$desktop-padding: 0rem 6rem;
$desktop-box-padding: 2rem 6rem;
$notepad-padding: 0rem 4rem;
$notepad-box-padding: 1rem 4rem;
$mobile-padding: 0rem .5rem;
$mobile-box-padding: .5rem .5rem;
$breakpoints: (
  "small": 768px,
  "medium": 1200px,
  "large": 1800px,
);
.cta-button {
  width: 100%;
  padding: .75rem 2rem;
  border: none;
  background-color: $primary-color;
  color: white;
  font-weight: bold;
}
@mixin responsive($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
      // Prints a media query based on the value
      @media (min-width: $value) {
        @content;
      }
    } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
  }