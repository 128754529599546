@import "./variables.scss";

.gallery {
    display: flex;
    flex-direction: column;
    h1 {
       padding: $mobile-padding;
       font-size: 1.25rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
    }
    padding-bottom: .25rem;
    .gallery-images-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: .25rem;
        column-gap: .25rem;
        .image-wrapper {
            display: flex;
            width: 100%;
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                opacity: .85;
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}
.image-viewer {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: .85);
    z-index: 99999;
    top: 0;
    .image-viewer-head {
        display: flex;
        justify-content: flex-end;
        height: 2rem;
        padding: 1rem;
        button {
            display: flex;
            align-items: center;
            border: none;
            padding: 1rem 2rem;
            background: black;
            cursor: pointer;
            svg {
                font-size: 1rem;
                color: white;
            }
            &:hover {
                svg {
                    color: $primary-color;
                }
            }
            margin-left: 1rem;
        }
        .button-wrapper {
            display: none;
        }
    }
    .image-viewer-main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-self: center;
        height: 250px;
        width: 100%;
        background: rgba($color: #000000, $alpha: .75);
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}

@include responsive(small) {
    .gallery {
        .gallery-images-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .image-wrapper {
                img {
                    max-height: 400px;
                }
            }
        }
    }

    .image-viewer {
        .image-viewer-main {
            height: 300px;
        }
        .image-viewer-head {
            .button-wrapper {
                display: flex;
                column-gap: 1rem;
                button {
                    display: flex;
                    margin: 0rem;
                }
            }
        }
    }

    
}

@include responsive(medium) {
    .gallery {
        h1 {
            padding: $notepad-padding;
            font-size: 2rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
         }
        .gallery-images-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    .image-viewer {
        .image-viewer-main {
            height: 500px;
        }
    }
}

@include responsive(large) {
    .gallery {
        h1 {
            padding: $desktop-padding;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .gallery-images-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    .image-viewer {
        .image-viewer-main {
            height: 700px;
        }
    }
}