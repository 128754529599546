@import "./variables.scss";

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
select,
textarea {
    width: 100%;
    padding: .75rem;
    border: 1px solid rgba($color: #000000, $alpha: .25);
    margin-bottom: 0.5rem;
    border-radius: .125rem;
    &:focus {
        outline: none;
        border: 1px solid #333;
    }
    background: rgba($color: #ffffff, $alpha: .25);
}
textarea {
    resize: none;
    height: 200px;
}
form {
    display: grid;
    width: 100%;
    label {
        font-size: .8rem;
    }
    p {
        &.error {
            color: red;
            font-weight: bold;
        }
    }
}

.contact-section {
    background: #484848;   
    color: white;
    padding: $mobile-padding;
    p {
        color: rgba($color: #dedede, $alpha: 1.0);
        max-width: 80%;
    }
    h1 {
        color: white;
        padding-bottom: 1rem;
    }
    ul {
        li {
            display: flex;
            padding: .25rem 0rem;
            color: rgba($color: #dedede, $alpha: 1.0);
            font-size: .9rem;
            .label {
                color: white;
                font-weight: bold;
                margin-right: .5rem;
                font-size: 1rem;
            }
            a {
                color: $primary-color;
                text-decoration: none;
                &:hover {
                    color: $tertiary-color;
                }
            }
        }
    }
    

}
.demo-section {
    display: grid;
    width: 100%;
    color: white;
    padding: $mobile-box-padding;
    background: #333;
    .left {
        p {
            color: rgba($color: #dedede, $alpha: 1.0);
            max-width: 100%;
        }
        h1 {
            color: white;
            padding-bottom: 1rem;
            text-align: center;
        }
        ul {
            li {
                display: flex;
                padding: .25rem 0rem;
                color: rgba($color: #dedede, $alpha: 1.0);
                font-size: .9rem;
                .label {
                    color: white;
                    font-weight: bold;
                    margin-right: .5rem;
                    font-size: 1rem;
                }
                a {
                    color: $primary-color;
                    text-decoration: none;
                    &:hover {
                        color: $tertiary-color;
                    }
                }
            }
        }
    }

    

}
.contact-form, .demo-form {
    display: grid;
    column-gap: 1rem;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1rem;
    color: white;
    background: white;
    .form-heading {
        padding: 1rem 0rem;
        h2 {
            color: #222;
            padding-bottom: 1rem;
            font-size: 2rem;
        }
    }
    .input-group {
        display: grid;
        row-gap: .25rem;

        label {
            font-weight: bold;
            color: #222;
            span {
                &.error {
                    color: red;
                    margin-left: .25rem;
                    font-weight: lighter;
                    font-size: .8rem;
                }
            }
        }
    }
    p {
        max-width: 60%;
        color: white;

    }

}
.demo-form {
    display: grid;
    column-gap: 1rem;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1rem;
    color: white;
    background: white;
}
.content-col {
    padding: 1rem;
}
.form-heading {
    color: #222;
    font-weight: bold;
}


@include responsive(small) {
    .contact-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        justify-content: flex-start;
        justify-items: flex-start;
        align-content: flex-start;
        padding: 2rem;
        .right {
            display: flex;
            justify-content: flex-end;
            justify-items: flex-end;
            width: 100%;
        }
        h1 {
            font-size: 2rem;
        }

    }
    .demo-section {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        justify-items: center;
        padding: $mobile-box-padding;
        .left {
            max-width: 600px;
        }
        .demo-form {
            max-width: 600px;
        }
    }
    .two-column-form-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 0rem;
    }
    .three-column-form-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
    }
    .contact-form {
        max-width: 450px;
        color: white;
        padding: 1rem;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        border-radius: .25rem;
    }
    .contact-form-page {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        padding: 0rem;
    }

    .content-col {
        padding: 2rem;
    }
    svg {
        margin-right: .5rem;
    }
}
@include responsive(medium) {
    .contact-section {
        padding: $notepad-padding;
    }
    .demo-section {
        display: grid;
        grid-template-columns: 2fr 1fr;
        padding: $notepad-box-padding;
        .demo-form {
            width: 700px;
        }
        .left {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            h1 {
                text-align: left;
                width: 100%;
            }
            p {
                width: 80%;
            }
        }
        .right {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
}
@include responsive(large) {
    .demo-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: $desktop-box-padding;
        width: 100%;
        .left {
            align-items: flex-start;
            justify-content: flex-start;
            h1 {
                text-align: left;
                width: 100%;
            }
        }
    }
    .contact-section {
        display: grid;
        grid-template-columns: 3fr 2fr;
        flex-direction: column;
        justify-content: flex-start;
        justify-items: flex-start;
        align-content: flex-start;
        padding: $desktop-box-padding;
        .right {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
    }
    .contact-form, .demo-form {
        color: white;
        padding: 1rem;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    }
    .contact-form-page {
        grid-template-columns: 1fr 2fr;
    }
}
