@import "./variables.scss";
@import "./navigation.scss";
@import "./home.scss";
@import "./footer.scss";
@import "./machinepage.scss";
@import "./pages.scss";
@import "./cards.scss";
@import "./sections.scss";
@import "./section_markets.scss";
@import "./section_requestdemo.scss";
@import "./columns.scss";
@import "./forms.scss";
@import "./support.scss";
@import "./imagespage.scss";
@import "./registration.scss";
@import "./series.scss";
@import "./gallery.scss";
@import "./individual-machine-gallery.scss";
@import "./applications.scss";
@import "./options.scss";
@import "./alert.scss";
@import "./blog.scss";
@import "./serial.scss";
@import "./partsPage.scss";
@import "./carousel.scss";
@import "./blog-post-page.scss";
@import "./all-blog-posts-page.scss";

* {
    margin: 0rem;
    box-sizing: border-box;
    padding: 0rem;
    font-family: sans-serif;
    // Remove scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
body {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
}
p {
    line-height: 1.5rem;
}

ul {
    list-style-type: none;
}
a {
    text-decoration: none;
}
.section-padded {
    display: flex;
    flex-direction: column;
    padding: $mobile-box-padding;
    &.dark {
        background-color: rgba($color: #000000, $alpha: .5);
    
    }
}
.page-wrapper {
    padding-top: 3.5rem;
}
.padded-section {
    padding: $mobile-box-padding;
}
button[type="submit"] {
    width: 100%;
    padding: 1rem;
    background: #333;
    color: white;
    border: none;
    cursor: pointer;
    &:hover {
        background: #444;
    }
}
@include responsive(medium) {
    .padded-section {
        padding: $notepad-box-padding;
    }
    .footer {
        display: flex;
        flex-direction: column;
        .footer-columns {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            padding: $notepad-box-padding;
            ul {
                display: grid;
                width: 100%;
            }
            align-items: flex-start;
        }
        .footer-image-wrapper {
            img {
                width: 300px;
                height: auto;
            }
        }
    }
}
@include responsive(large) {
    .section-padded {
        padding: $desktop-box-padding;
    }
    .padded-section {
        padding: $desktop-box-padding;
    }
    .footer {
        .footer-columns {
            display: flex;
            width: 100%;
            padding: $desktop-box-padding;
            ul {
                display: grid;
                width: 100%;
            }
            align-items: flex-start;
        }

    }
}