@import "./variables.scss";

.machine-page {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;

}
.manuals-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(4, auto);
    justify-content:space-evenly;
    width: 100%;
    padding: 1rem 0rem;
    row-gap: 1rem;
    a {
        color: black;
    }
    .manual {
        display: grid;
        align-items: flex-start;
        align-content: flex-start;
        width: 130px;
        justify-content: center;
        justify-items: center;
        cursor: pointer;
        row-gap: .5rem;
        .manual-image {
            height: 30px;
            width: 30px;
            img {
                object-fit: contain;
                width: 100%;
                height: auto;
            
            }
        }
        .manual-title {
            text-decoration: underline;
            width: min-content;
            text-align: center;
            font-size: .8rem;
        }
    }
}
.mobile-machine-page {
    display: grid;
    align-items: flex-start;
    .mobile-machine-deck-options {
        display: flex;
        row-gap: .35rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .125);
        width: 100%;
        padding: .5rem;
        .deck-option {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #333;
            width: max-content;
            padding: .25rem .5rem;
            border-radius: .125rem;
            color: white;
            font-size: .85rem;
            cursor: pointer;
        }
        h2 {
            display: flex;
            font-size: 1.125rem;
            justify-content: center;
            flex-basis: 100%;
            text-align: center;
        }
    
    }
    .mobile-machine-image-wrapper {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        padding-top: 1rem;
        height: max-content;
        overflow: hidden;
        .arrows {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            position: absolute;
            justify-content: space-between;
            padding: 0rem .5rem;
            font-size: 2.5rem;
        }
        img {
            display: flex;
            width: 70%;
            height: max-content;
            object-fit: contain;
        }
    }
    .mobile-machine-general-data {
        padding: .5rem;
        h4 {
            color: #555;
        }
    }
    .mobile-machine-description {
        display: grid;
        align-items: flex-start;
        flex-direction: column;
        padding: 0rem .5rem;
        h1 {
            color: #666;
            font-size: 1.5rem;
            margin-bottom: .25rem;
        }
        h2 {
            color: #444;
            font-size: 1.25rem;
            margin-bottom: .25rem;
        }
        p {
            color: #3d3d3d;
            font-size: .8rem;
            line-height: 1.25rem;
            margin-bottom: .5rem;
        }
    }
    .mobile-machine-cta-button-wrapper {
        padding: 0rem .5rem;
        a {
            display: flex;
            text-align: center;
            justify-content: center;
            align-content: center;
            width: 100%;
            color: white;
            padding: .75rem 1.5rem;
            background: $primary-color;
        }
    }
    .mobile-machine-manuals {
        display: grid;
        margin: .5rem;
        border: 1px solid rgba($color: #000000, $alpha: .125);
        .manuals-header {
            display: flex;
            align-items: center;
            padding: .5rem;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .075);
            svg {
                font-size: 1.25rem;
            }
            h4 {
                color: #555;
            }
        
        }
    }
}
.notepad-machine-page {
    display: none;
}

@include responsive(medium) {
    .mobile-machine-page {
        display: none;
    }
    .notepad-machine-page {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .notepad-machine-image-thumbs-wrapper {
            display: grid;
            position: relative;
            justify-content: center;
            width: 100%;
            padding: 1rem 0rem;
            height: max-content;
            overflow: hidden;
            .notepad-machine-image-wrapper {
                display: flex;
                justify-content: center;
                img {
                    display: flex;
                    width: 65%;
                    height: max-content;
                    object-fit: contain;
                }
            }
            .notepad-machine-thumbs {
                display: flex;
                justify-items: flex-start;
                justify-content: space-evenly;
                width: 100%;
                .thumb {
                    display: flex;
                    height: auto;
                    width: 20%;
                    img {
                        object-fit: contain;
                        width: 80%;
                        height: max-content;
                        cursor: pointer;
                    }
                }
            }
        }
        .notepad-machine-general-data {
            display: grid;
            align-content: flex-start;
            padding: 1rem;
            h4 {
                color: #555;
            }
        }
        .notepad-machine-description {
            display: grid;
            flex-direction: column;
            padding: .5rem 0rem 0rem 0rem;
            h1 {
                color: #666;
                font-size: 1.5rem;
                margin-bottom: .25rem;
            }
            h2 {
                color: #444;
                font-size: 1.25rem;
                margin-bottom: .25rem;
            }
            p {
                color: #3d3d3d;
                font-size: .8rem;
                line-height: 1.25rem;
            }
        }
        .notepad-deck-options {
            display: flex;
            justify-content: space-around;
            column-gap: .25rem;
            padding: .5rem 0rem .5rem 0rem;
            border-top: 1px solid rgba($color: #000000, $alpha: .125);
            .notepad-deck-option {
                display: grid;
                grid-template-rows: 1fr auto;
                flex-direction: column;
                justify-content: center;
                justify-items: center;
                text-align: center;
                cursor: pointer;
                transition: all .25s ease-in-out;
                img {
                    width: 100px;
                    height: auto;
                    object-fit: contain;
                    transition: all .25s ease-in-out;
                }
                &:hover {
                    img {
                        filter: brightness(1.125);
                        transform: scale(1.1);
                    }
                }
            }
        }
        .notepad-machine-cta-button-wrapper {
            display: flex;
            justify-content: center;
            a {
                display: flex;
                text-align: center;
                justify-content: center;
                align-content: center;
                width: 100%;
                color: white;
                padding: .75rem 1.5rem;
                background: $primary-color;
            }
        }
        .notepad-machine-manuals {
            display: grid;
            margin: .5rem;
        }
    }
    .notepad-machine-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        justify-items: center;
        a {
            display: flex;
            align-items: center;
            color: white;
            padding: .5rem 1rem;
            background: orange; 
            color: white;
            transition: all .25s ease-in-out;
            &:hover {
                background: #ff6600;
            }
        }
    }
    .manuals-wrapper {
        justify-content:space-between;
        border-top: 1px solid rgba($color: #000000, $alpha: .125);
        .manual {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            width: 100px;
            cursor: pointer;
            row-gap: .5rem;
            transition: all .25s ease-in-out;
            &:hover {
                .manual-image {
                    svg {
                        fill: $primary-color;
                        transition: all .25s ease-in-out;
                    }
                }
                .manual-title {
                    color: $primary-color;

                }
            }
            .manual-title {
                transition: all .25s ease-in-out;
                text-align: center;
            }
            .manual-image {
                display: grid;
                svg {
                    display: grid;
                    width: 100%;
                    margin: 0rem;
                    height: auto;
                    fill: #333;
                    transition: all .25s ease-in-out;
                }
            }
        }
    }
}

@include responsive(large) {
    .notepad-machine-page {
        .notepad-machine-image-thumbs-wrapper {
            .notepad-machine-image-wrapper {
                img {
                    width: 40%;
                }
            }
            .notepad-machine-thumbs {
                .thumb {
                    width: 15%;
                    img {
                        width: 80%;
                    }
                }
            }
        }
        .notepad-machine-general-data {
            display: grid;
            align-content: flex-start;
            padding: 1rem;
            h4 {
                color: #555;
            }
        }
        .notepad-machine-description {
            display: grid;
            flex-direction: column;
            padding: .5rem 0rem 0rem 0rem;
            h1 {
                color: #666;
                font-size: 1.5rem;
                margin-bottom: .25rem;
            }
            h2 {
                color: #444;
                font-size: 1.25rem;
                margin-bottom: .25rem;
            }
            p {
                color: #3d3d3d;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        .notepad-deck-options {
            display: flex;
            justify-content: space-around;
            column-gap: .25rem;
            padding: .5rem 0rem .5rem 0rem;
            border-top: 1px solid rgba($color: #000000, $alpha: .125);
            .notepad-deck-option {
                display: grid;
                grid-template-rows: 1fr auto;
                flex-direction: column;
                justify-content: center;
                justify-items: center;
                text-align: center;
                img {
                    width: 100px;
                    height: auto;
                    object-fit: contain;
                
                }
            }
        }
        .notepad-machine-cta-button-wrapper {
            display: flex;
            justify-content: center;
            a {
                display: flex;
                text-align: center;
                justify-content: center;
                align-content: center;
                width: 100%;
                color: white;
                padding: .75rem 1.5rem;
                background: $primary-color;
            }
        }
        .notepad-machine-manuals {
            display: grid;
            margin: .5rem;
        }
    }
}